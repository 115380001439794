<template>
  <div class="container">
    <button @click="open" class="add-circle" id="add-circle">
      <font-awesome-icon :icon="faPlus" />
    </button>
    <div class="cards">
      <transition-group name="slide-up" tag="ul" class="list">
        <li v-for="item in newItems" :key="item.id" class="item">
          <div v-if="Boolean(Number(item.completed))" class="card completed">
            <h1>Title: {{ item.name }}</h1>
            <p>Description: {{ item.description }}</p>
            <div class="cancel" @click="remItem(item.id)">
              <font-awesome-icon :icon="faTrash" />
            </div>
            <div class="form-label">
              <input
                class="checkbox"
                @change="updItem(item)"
                type="checkbox"
                checked
              />
              <div>Completed</div>
            </div>
          </div>
          <div
            v-if="!Boolean(Number(item.completed))"
            class="card not-completed"
          >
            <h1>Title: {{ item.name }}</h1>
            <p>Description: {{ item.description }}</p>
            <div class="cancel" @click="remItem(item.id)">
              <font-awesome-icon :icon="faTrash" />
            </div>
            <div class="form-label">
              <input class="checkbox" @change="updItem(item)" type="checkbox" />
              <div>Completed</div>
            </div>
          </div>
        </li>
      </transition-group>
    </div>

    <div class="item-card" id="add-card">
      <div class="card no-board">
        <h2>Add a new TODO</h2>
        <form action="" autocomplete="off" @submit="addNewItem">
          <div>
            <button type="submit" class="circle">
              <font-awesome-icon :icon="faPlus" />
            </button>
          </div>

          <div class="title">
            <input
              type="text"
              name="title"
              placeholder="Give a title"
              v-model="newitem.name"
              required
            />
          </div>
          <div class="description">
            <textarea
              type="text"
              name="description"
              placeholder="Give a description"
              v-model="newitem.description"
              required
              rows="4"
            ></textarea>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Cards",
  data() {
    return {
      respons: {},
      items: [],
      newitem: {
        id: "",
        name: "",
        description: "",
        completed: 0
      }
    };
  },
  methods: {
    ...mapActions([
      "getTest",
      "getItems",
      "addItem",
      "removeItem",
      "updateItem"
    ]),
    addNewItem(e) {
      e.preventDefault();
      this.addItem(this.newitem);
      this.newitem.name = "";
      this.newitem.description = "";
    },
    remItem(i) {
      let req = { id: i };
      this.removeItem(req);
    },
    updItem(i) {
      let req = { id: i.id, completed: i.completed };
      this.updateItem(req);
    },
    open() {
      if (
        document.getElementById("add-card").style.transform ==
        `translateY(-80vh)`
      ) {
        document.getElementById("add-card").style.transform = `translateY(0vh)`;

        document.querySelector(".add-circle").style.transform = `rotate(45deg)`;
        document.querySelector(
          ".add-circle"
        ).style.background = `rgb(255, 99, 71)`;
      } else {
        document.getElementById(
          "add-card"
        ).style.transform = `translateY(-80vh)`;

        document.querySelector(
          ".add-circle"
        ).style.background = `rgb(58, 90, 65)`;
        document.querySelector(".add-circle").style.transform = `rotate(0deg)`;
      }
    },
    moveCircle() {
      let tL = gsap.timeline();
      tL.to(".add-circle", { x: 0, y: 650, duration: 1, ease: "expo.in" });
    }
  },
  computed: {
    ...mapGetters(["newResponse", "newItems"]),
    faTrash() {
      return faTrash;
    },
    faPlus() {
      return faPlus;
    }
  },
  mounted() {
    this.moveCircle();
  },
  created() {
    this.getItems();
  }
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.container {
  height: 100vh;
  width: 98%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
#add-card {
  transform: translateY(-80vh);
  transition: transform 0.8s ease-in;
}
.form-label {
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: baseline;
}
/* flex  */
.cards {
  height: 100vh;
  width: 100%;
}
.item-card {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}
.cards ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.card {
  position: relative;
  transform-style: preserve-3d;
  width: 280px;
  min-height: 200px;
  text-align: left;
  border-radius: 5px;
  margin: 1rem;
  padding: 2rem;
  background: white;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2), 0px 0px 50px rgba(0, 0, 0, 0.2);
  transition: all 200ms ease-in-out;
}
.card h1 {
  font-size: 1.5rem;
}
.card p {
  font-size: 0.8rem;
}
.cancel {
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  position: absolute;
  border-radius: 50%;
  z-index: 1;
  font-size: 1.2rem;
  align-items: center;
  justify-items: center;
  text-align: center;
  cursor: pointer;
}
.circle {
  bottom: 1rem;
  right: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  border-radius: 50%;
  z-index: 1;
  font-size: 1.5rem;
  color: white;
  justify-items: center;
  text-align: center;
  cursor: pointer;
  background: yellowgreen;
  border: 2px solid rgb(0, 37, 2);

  transition: all 500ms ease;
  outline-style: none;
}
.circle:hover {
  transform: rotate(360deg);
}
.card input,
textarea {
  width: 100%;
  padding: 1rem 1rem;
  margin-top: 1rem;
  font-size: 1.2rem;
  border-radius: 8px;
  outline-style: none;
}
.card:hover {
  transform: scale(1.02);
}
.completed {
  border: 9px dashed rgb(58, 90, 65);
}
.not-completed {
  border: 9px dashed rgb(255, 99, 71);
}
.no-board {
  border: 9px solid rgb(255, 99, 71);
  border-radius: 9px;
  width: 100%;
}
/** slide-up transition */
.slide-up-enter {
  transform: translateY(100px);
  opacity: 0;
}
.slide-up-enter-active {
  transition: all 700ms ease;
}
.add-circle {
  top: 0;
  right: 0;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  border-radius: 50%;
  z-index: 3;
  font-size: 1.5rem;
  color: white;
  justify-items: center;
  text-align: center;
  cursor: pointer;
  background: yellowgreen;
  border: 3px solid rgb(0, 0, 0);

  transition: all 900ms ease;
  outline-style: none;
}
.add-circle:hover {
  transform: rotate(360deg);
}
</style>
